import { layerColors } from "./platform-configs/config";
import { ciplaConfig, ciplaMonitoringConfig } from "./platform-configs/config.cipla";
import {india1SalesIntelConfig} from "./platform-configs/config.salesintel.india1";
const config = india1SalesIntelConfig;
const monitoringConfig = ciplaMonitoringConfig;

export const environment = {
  production: false,
  API_URL: config.apiUrl,
  BASE_URL: config.baseUrl,
  ELASTIC_BASE_URL: config.elasticUrl,
  ENABLE_KC_LOGIN_REDIRECT: config.enableKCLoginRedirect,
  MAPBOX_TOKEN:
    "pk.eyJ1IjoiYW5qYW5wdXRyYSIsImEiOiJja2M3MG15aW4wZ2R5MndwaDEydmZrOGp5In0.ugo5Q3Ymqb950LR9yVqYvw",
  USER_LOGO_URL: "USER_LOGO_URL",
  WEBSOCKET_URL: "",
  LAYER_COLORS: layerColors,
  DEFAULT_MODULE: config?.defaultModule,
  LAYERSETS_CONFIG: config.layerSetConfig,
  FILTERSETS_CONFIG: config.defaultFilterSetConfig,
  TABLE_CONFIG: config?.defaultTableConfig ?? null,
  POI_RELATIONSHIP_CONFIG: config.poiRelationshipConfig,
  BARRED_POIs: config.barredPOIs,
  ENABLE_ADVANCED_SEARCH: config?.enableAdvancedSearch ?? false,
  ENABLE_DS_MAPS_DASHBOARD: config?.enableDSMapsDashboard ?? false,
  ENABLE_GEOGRAPHY_MULTI_SELECT: config?.enableGeographyMultiSelect ?? false,
  ENABLE_RAISE_AN_ISSUE: config?.enableRaiseAnIssue ?? false,
  ENABLE_RANK_BY_FEATURE: config?.enableRankByFeature ?? false,
  ENABLE_SHOW_VALUES_FOR_FEATURE: config?.enableShowValuesForFeature ?? false,
  ENABLED_MODULES: config?.enableDashboardModules ?? [],
  HAS_CLUSTERS: config?.hasClusters ?? false,
  LAYER_OBJECTS_SELECTION_LIMIT: config?.layerObjectsSelectionLimit ?? 4,
  ENABLE_REGISTRATION: config?.enableRegistration ?? false,
  ADVANCED_DEEP_DIVE: config?.advancedDeepDive ?? false,
  DEFAULT_GEOGRAPHY_AREA_THRESHOLD: config?.defaultGeographyAreaThreshold,
  MAPBOX_BASE_MAP_STYLE: config?.mapboxBaseMapStyle ?? "dark",
  DESIGNATION_USER_ROLE_MAPPING: config?.designationUserRoleMapping,
  // MONITORING DAHSBOARD CONFIG
  MT_TOP_USER_ID: monitoringConfig.topUserId,
  MT_TOP_USER_TEAM_IDS: monitoringConfig.topUserTeamIds,
  MT_DEFAULT_START_DATE: monitoringConfig?.defaultStartDate ?? null,
  MT_DEFAULT_END_DATE: monitoringConfig?.defaultEndDate ?? null,
  MT_OPP_TAPPED_TABLE: monitoringConfig?.opportunityTappedTable ?? null,
  MT_OPP_TAPPED_TABLE_HIST: monitoringConfig?.opportunityTappedTableHistory ?? null
};
