import { FilterSetGroup } from "src/app/blades/explore/models/filter.model";
import {
  CreateLayerSetConfig,
  DeckLayerTypes,
  LayerTypes
} from "src/app/blades/explore/models/layer.model";
import {
  TableColumnsObject,
  ExportColumnsObject
} from "src/app/blades/explore/models/poi-table.model";
import { layerColors, PlatformConfig } from "./config";

export const gridsFS: FilterSetGroup[] = [
  {
    name: "Potentail",
    prop: "potential",
    filters: [
      //"1km_business_potential",
      // "600m_business_potential",
      "150m_business_potential"
    ]
  },
  {
    name: "Demographic",
    prop: "demographicIndex",
    filters: [
      "affluence",
      "footfall",
      "hIG_%",
      "income",
      "lIG_%",
      "mIG_%",
      "market_presence",
      "office_presence",
      "pincode_category",
      "commercial_activity_value",
      "economic_activity_value",
      "median_rent_per_square_feet"
    ]
  },
  {
    name: "Amenities",
    prop: "amenitiesIndex",
    filters: [
      "aTM_count_(500m)",
      "apartment_complex_count_(500m)",
      "bank_count_(500m)",
      "clothing_store_count_(500m)",
      "pharmacy_count_(500m)",
      "restaurant_count_(500m)",
      "travel_agent_count_(500m)"
    ]
  }
];

export const defaultFS: FilterSetGroup[] = [
  {
    name: "Internal Properties",
    prop: "internalFilter",
    filters: ["cash_loading_type", "merchant_type", "atm_id"]
  },
  ...gridsFS
];

export const atmFS: FilterSetGroup[] = [
  {
    name: "Internal Properties",
    prop: "internalFilter",
    filters: [
      "atm_id",
      "brand",
      "cash_loading_type",
      "district",
      "merchant_type",
      "pincode",
      "city",
      "district",
      "state"
    ]
  },
  ...gridsFS
];

const india1FS: FilterSetGroup[] = [
  {
    name: "Internal Properties",
    prop: "internalFilter",
    filters: [
      "age(in_months)",
      "atm_id",
      "cash_loading_type",
      "district",
      "merchant_type",
      "pincode",
      "city",
      "district",
      "state",
      "transaction_count(median)",
      "transaction_count(quartile_range)"
    ]
  },
  ...gridsFS
];

const boxFS: FilterSetGroup[] = [
  {
    name: "Internal Properties",
    prop: "internalFilter",
    filters: [
      "Commercial_Activity_Value",
      "Clothing_Store_Count",
      "MIG_%",
      "Pharmacy_Count",
      "Office_Presence",
      "Business_Potential",
      "Restaurant_Count",
      "Income",
      "HIG_%",
      "Pincode_Category",
      "Market_Presence",
      "Apartment_Complex_Count",
      "Footfall",
      "ATM_Count",
      "Total_POI_Count",
      "Median_Rent_Per_Square_Feet",
      "Bank_Count",
      "LIG_%",
      "Economic_Activity_Value",
      "Travel_Agent_Count",
      "Affluence"
    ]
  }
];

const pincodeFSGroup: FilterSetGroup[] = [
  {
    name: "ATM Info",
    prop: "atmInfo",
    filters: ["ATM_Count", "ATM_Density", "ATM_Saturation"]
  },
  {
    name: "Market Leader Info",
    prop: "marketLeaderInfo",
    filters: ["Market_Leader", "Market_Leader_Penetration"]
  },
  {
    name: "Bank Info",
    prop: "bankInfo",
    filters: ["Bank_Count", "Bank_Density"]
  },
  {
    name: "Demographic",
    prop: "demographicIndex",
    filters: [
      "Affluence",
      "Amenities_Count",
      "Amenities_Density",
      "Commercial_Retail_Count",
      "Commercial_Retail_Density",
      "Commercial_Services_Count",
      "Commercial_Services_Density",
      "Digitization_Growth",
      "Finance_Institution_Density",
      "Financial_Institution_Count",
      "Footfall",
      "HIG_%",
      "Healthcare_Count",
      "Healthcare_Density",
      "High_Deliquency_%",
      "industrial_pincode",
      "Infrastructure_Count",
      "Infrastructure_Density",
      "LIG_%",
      "Lending_Activity_Value",
      "MIG_%",
      "Mean_Resident_Income",
      "Non_Deliquency_%",
      "Number_Of_Households",
      "Pincode_Category",
      "Population",
      "Transport_Count",
      "Transport_Density",
      "Utilities_Count",
      "Utilities_Density"
    ]
  }
];

const gridsTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Grid Hash", prop: "hash", isSortable: false, isString: true },
    { name: "Latitude", key: null, prop: "latitude", isSortable: true, isString: false },
    { name: "Longitude", key: null, prop: "longitude", isSortable: true, isString: false },
    {
      name: "Village/Town",
      key: "index",
      prop: "village_name",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode",
      key: null,
      prop: "Pincode",
      isSortable: true,
      isString: true
    },
    { name: "City", key: null, prop: "City", isSortable: false, isString: true },
    {
      name: "150m Business Potential",
      key: "index",
      prop: "150m_business_potential",
      isSortable: true,
      isString: true
    },
    // {
    // name: "600m Business Potential",
    // key: "index",
    // prop: "600m_business_potential",
    // isSortable: true,
    // isString: true
    // },
    // {
    // name: "1KM Business Potential",
    // key: "index",
    //  prop: "1km_business_potential",
    //  isSortable: true,
    // isString: true
    //},
    {
      name: "Affluence",
      key: "index",
      prop: "affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "index",
      prop: "footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "index",
      prop: "hIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "index",
      prop: "income",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "index",
      prop: "lIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "index",
      prop: "mIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Presence",
      key: "index",
      prop: "market_presence",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Presence",
      key: "index",
      prop: "office_presence",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode Category",
      key: "index",
      prop: "pincode_category",
      isSortable: false,
      isString: true
    },
    {
      name: "ATM Count (500m)",
      key: "index",
      prop: "aTM_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Apartment Complex Count (500m)",
      key: "index",
      prop: "apartment_complex_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count (500m)",
      key: "index",
      prop: "bank_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count (500m)",
      key: "index",
      prop: "clothing_store_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Pharmacy Count (500m)",
      key: "index",
      prop: "pharmacy_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count (500m)",
      key: "index",
      prop: "restaurant_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Travel Agent Count (500m)",
      key: "index",
      prop: "travel_agent_count_(500m)",
      isSortable: true,
      isString: false
    }
  ]
};

const boxTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Name", prop: "name", isSortable: false, isString: true },
    {
      name: "Pincode",
      key: null,
      prop: "Pincode",
      isSortable: true,
      isString: true
    },
    {
      name: "Business Potential",
      key: "internalDetails",
      prop: "Business_Potential",
      isSortable: false,
      isString: true
    },
    {
      name: "Center Latitude",
      key: "internalDetails",
      prop: "Centre_Latitude",
      isSortable: false,
      isString: true
    },
    {
      name: "Center Longitude",
      key: "internalDetails",
      prop: "Centre_Longitude",
      isSortable: false,
      isString: true
    },
    {
      name: "Commercial Activity Value",
      key: "internalDetails",
      prop: "Commercial_Activity_Value",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Pharmacy Count",
      key: "internalDetails",
      prop: "Pharmacy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Presence",
      key: "internalDetails",
      prop: "Office_Presence",
      isSortable: true,
      isString: true
    },
    {
      name: "Business Potential",
      key: "internalDetails",
      prop: "Business_Potential",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Pincode Category",
      key: "internalDetails",
      prop: "Pincode_Category",
      isSortable: true,
      isString: true
    },
    {
      name: "Market Presence",
      key: "internalDetails",
      prop: "Market_Presence",
      isSortable: true,
      isString: true
    },
    {
      name: "Apartment Complex Count",
      key: "internalDetails",
      prop: "Apartment_Complex_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Total POI Count",
      key: "internalDetails",
      prop: "Total_POI_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Centre Longitude",
      key: "internalDetails",
      prop: "Centre_Longitude",
      isSortable: true,
      isString: false
    },
    {
      name: "Median Rent Per Square Feet",
      key: "internalDetails",
      prop: "Median_Rent_Per_Square_Feet",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Bank_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Economic Activity Value",
      key: "internalDetails",
      prop: "Economic_Activity_Value",
      isSortable: true,
      isString: false
    },
    {
      name: "Centre Latitude",
      key: "internalDetails",
      prop: "Centre_Latitude",
      isSortable: true,
      isString: false
    },
    {
      name: "Travel Agent Count",
      key: "internalDetails",
      prop: "Travel_Agent_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "GMap Link",
      key: "internalDetails",
      prop: "Gmap_Link",
      isSortable: true,
      isString: false,
      isLink: true
    },
    { name: "City", prop: "City", isSortable: false, isString: true },
    { name: "District", prop: "District", isSortable: false, isString: true },
    { name: "State", prop: "State", isSortable: false, isString: true }
  ]
};

const boxExp: ExportColumnsObject = {
  exportType: "all",
  columns: [
    { name: "Name", prop: "name", isString: true },
    {
      name: "Pincode",
      key: null,
      prop: "Pincode",
      isString: true
    },
    {
      name: "Business Potential",
      key: "internalDetails",
      prop: "Business_Potential",
      isString: true
    },
    {
      name: "Center Latitude",
      key: "internalDetails",
      prop: "Centre_Latitude",
      isString: true
    },
    {
      name: "Center Longitude",
      key: "internalDetails",
      prop: "Centre_Longitude",
      isString: true
    },
    { name: "City", prop: "City", isString: true },
    { name: "District", prop: "District", isString: true },
    { name: "State", prop: "State", isString: true }
  ]
};

const india1TableColumns: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    { name: "Address", key: null, prop: "address", isSortable: false, isString: true },
    {
      name: "Atm Id",
      key: "internalDetails",
      prop: "ATM_ID",
      isSortable: true,
      isString: true
    },
    {
      name: "Cash Loading Type",
      key: "internalDetails",
      prop: "cash_loading_type",
      isSortable: false,
      isString: true
    },
    {
      name: "District",
      key: "index",
      prop: "district",
      isSortable: false,
      isString: true
    },
    {
      name: "Merchant Type",
      key: "internalDetails",
      prop: "merchant_type",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode",
      key: "index",
      prop: "pincode",
      isSortable: false,
      isString: true
    },
    {
      name: "City",
      key: "index",
      prop: "city",
      isSortable: false,
      isString: true
    },
    {
      name: "State",
      key: "index",
      prop: "state",
      isSortable: false,
      isString: true
    },
    { name: "Latitude", key: null, prop: "latitude", isSortable: true, isString: false },
    { name: "Longitude", key: null, prop: "longitude", isSortable: true, isString: false },
    //{
    // name: "Transaction Count(median)",
    //key: "internalDetails",
    //prop: "transaction_count(median)",
    //isSortable: true,
    //isString: false
    //},
    //{
    //name: "Transaction Count(quartile Range)",
    //key: "internalDetails",
    //prop: "transaction_count(quartile_range)",
    //isSortable: false,
    //isString: true
    //}
    {
      name: "Village/Town",
      key: "index",
      prop: "village_name",
      isSortable: false,
      isString: true
    },
    {
      name: "150m Business Potential",
      key: "index",
      prop: "150m_business_potential",
      isSortable: true,
      isString: true
    },
    {
      name: "Affluence",
      key: "index",
      prop: "affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "index",
      prop: "footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "index",
      prop: "hIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "index",
      prop: "income",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "index",
      prop: "lIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "index",
      prop: "mIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Presence",
      key: "index",
      prop: "market_presence",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Presence",
      key: "index",
      prop: "office_presence",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode Category",
      key: "index",
      prop: "pincode_category",
      isSortable: false,
      isString: true
    },
    {
      name: "ATM Count (500m)",
      key: "index",
      prop: "aTM_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Apartment Complex Count (500m)",
      key: "index",
      prop: "apartment_complex_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count (500m)",
      key: "index",
      prop: "bank_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count (500m)",
      key: "index",
      prop: "clothing_store_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Pharmacy Count (500m)",
      key: "index",
      prop: "pharmacy_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count (500m)",
      key: "index",
      prop: "restaurant_count_(500m)",
      isSortable: true,
      isString: false
    },
    {
      name: "Travel Agent Count (500m)",
      key: "index",
      prop: "travel_agent_count_(500m)",
      isSortable: true,
      isString: false
    }
  ]
};

const atmTableColumns: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    { name: "Address", key: null, prop: "address", isSortable: false, isString: true },
    {
      name: "Brand",
      key: "internalDetails",
      prop: "brand",
      isSortable: false,
      isString: true
    },
    {
      name: "District",
      key: "index",
      prop: "district",
      isSortable: false,
      isString: true
    },
    {
      name: "Merchant Type",
      key: "internalDetails",
      prop: "merchant_type",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode",
      key: "index",
      prop: "pincode",
      isSortable: false,
      isString: true
    },
    {
      name: "City",
      key: "index",
      prop: "city",
      isSortable: false,
      isString: true
    },
    {
      name: "State",
      key: "index",
      prop: "state",
      isSortable: false,
      isString: true
    },
    { name: "Latitude", key: null, prop: "latitude", isSortable: true, isString: false },
    { name: "Longitude", key: null, prop: "longitude", isSortable: true, isString: false }
  ]
};

const pincodeTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isSortable: false, isString: true },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Density",
      key: "internalDetails",
      prop: "ATM_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Saturation",
      key: "internalDetails",
      prop: "ATM_Saturation",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Amenities Count",
      key: "internalDetails",
      prop: "Amenities_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Amenities Density",
      key: "internalDetails",
      prop: "Amenities_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Bank_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Density",
      key: "internalDetails",
      prop: "Bank_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "Commercial Retail Count",
      key: "internalDetails",
      prop: "Commercial_Retail_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Commercial Retail Density",
      key: "internalDetails",
      prop: "Commercial_Retail_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "Commercial Services Count",
      key: "internalDetails",
      prop: "Commercial_Services_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Commercial Services Density",
      key: "internalDetails",
      prop: "Commercial_Services_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "Digitization Growth",
      key: "internalDetails",
      prop: "Digitization_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Financial Institution Density",
      key: "internalDetails",
      prop: "Financial_Institution_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "Financial Institution Count",
      key: "internalDetails",
      prop: "Financial_Institution_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Daily_Commercial_Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Healthcare Count",
      key: "internalDetails",
      prop: "Healthcare_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Healthcare Density",
      key: "internalDetails",
      prop: "Healthcare_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "High Deliquency %",
      key: "internalDetails",
      prop: "SME_High_Deliquency_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Industrial Pincode",
      key: "internalDetails",
      prop: "industrial_pincode",
      isSortable: false,
      isString: true
    },
    {
      name: "Infrastructure Count",
      key: "internalDetails",
      prop: "Infrastructure_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Infrastructure Density",
      key: "internalDetails",
      prop: "Infrastructure_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Lending Activity Value",
      key: "internalDetails",
      prop: "Lending_Activity_Value",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Leader",
      key: "internalDetails",
      prop: "Market_Leader",
      isSortable: false,
      isString: true
    },
    {
      name: "Market Leader Penetration",
      key: "internalDetails",
      prop: "Market_Leader_Penetration",
      isSortable: true,
      isString: false
    },
    {
      name: "Mean Resident Income",
      key: "internalDetails",
      prop: "Mean_Resident_Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Non Deliquency %",
      key: "internalDetails",
      prop: "SME_Non_Deliquency_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Number Of Households",
      key: "internalDetails",
      prop: "Number_Of_Households",
      isSortable: true,
      isString: false
    },
    {
      name: "Pincode Category",
      key: "internalDetails",
      prop: "Pincode_Category",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Transport Count",
      key: "internalDetails",
      prop: "Transport_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transport Density",
      key: "internalDetails",
      prop: "Transport_Density",
      isSortable: true,
      isString: false
    },
    {
      name: "Utilities Count",
      key: "internalDetails",
      prop: "Utilities_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Utilities Density",
      key: "internalDetails",
      prop: "Utilities_Density",
      isSortable: true,
      isString: false
    }
  ]
};

const gridsExport: ExportColumnsObject = {
  exportType: "all",
  columns: [
    { name: "Grid Hash", prop: "hash", isString: true },
    { name: "Latitude", key: null, prop: "latitude" },
    { name: "Longitude", key: null, prop: "longitude" },
    {
      name: "Village/Town ID",
      key: "index",
      prop: "village_id",
      isString: true
    },
    {
      name: "Village/Town",
      key: "index",
      prop: "village_name",
      isString: true
    },
    { name: "Pincode", key: null, prop: "Pincode", isString: true },
    { name: "City", key: null, prop: "City", isString: true },
    { name: "GMap Link", prop: null, gMapLink: true },
    {
      name: "Affluence",
      key: "index",
      prop: "affluence",
      isString: false
    },
    {
      name: "150m Business Potential",
      key: "index",
      prop: "150m_business_potential",
      isString: true
    },
    {
      name: "1KM Business Potential",
      key: "index",
      prop: "1km_business_potential",
      isString: true
    },
    {
      name: "Footfall",
      key: "index",
      prop: "footfall",
      isString: false
    },
    {
      name: "HIG %",
      key: "index",
      prop: "hIG_%",
      isString: false
    },
    {
      name: "Income",
      key: "index",
      prop: "income",
      isString: false
    },
    {
      name: "LIG %",
      key: "index",
      prop: "lIG_%",
      isString: false
    },
    {
      name: "MIG %",
      key: "index",
      prop: "mIG_%",
      isString: false
    },
    {
      name: "Market Presence",
      key: "index",
      prop: "market_presence",
      isString: true
    },
    {
      name: "Office Presence",
      key: "index",
      prop: "office_presence",
      isString: true
    },
    {
      name: "Pincode Category",
      key: "index",
      prop: "pincode_category",
      isString: false
    },
    {
      name: "ATM Count (500m)",
      key: "index",
      prop: "aTM_count_(500m)",
      isString: false
    },
    {
      name: "Apartment Complex Count (500m)",
      key: "index",
      prop: "apartment_complex_count_(500m)",
      isString: false
    },
    {
      name: "Bank Count (500m)",
      key: "index",
      prop: "bank_count_(500m)",
      isString: false
    },
    {
      name: "Clothing Store Count (500m)",
      key: "index",
      prop: "clothing_store_count_(500m)",
      isString: false
    },
    {
      name: "Pharmacy Count (500m)",
      key: "index",
      prop: "pharmacy_count_(500m)",
      isString: false
    },
    {
      name: "Restaurant Count (500m)",
      key: "index",
      prop: "restaurant_count_(500m)",
      isString: false
    },
    {
      name: "Travel Agent Count (500m)",
      key: "index",
      prop: "travel_agent_count_(500m)",
      isString: false
    }
  ]
};

const pincodeExp: ExportColumnsObject = {
  exportType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isString: true },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isString: false
    },
    {
      name: "ATM Density",
      key: "internalDetails",
      prop: "ATM_Density",
      isString: false
    },
    {
      name: "ATM Saturation",
      key: "internalDetails",
      prop: "ATM_Saturation",
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isString: false
    },
    {
      name: "Amenities Count",
      key: "internalDetails",
      prop: "Amenities_Count",
      isString: false
    },
    {
      name: "Amenities Density",
      key: "internalDetails",
      prop: "Amenities_Density",
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Bank_Count",
      isString: false
    },
    {
      name: "Bank Density",
      key: "internalDetails",
      prop: "Bank_Density",
      isString: false
    },
    {
      name: "Commercial Retail Count",
      key: "internalDetails",
      prop: "Commercial_Retail_Count",
      isString: false
    },
    {
      name: "Commercial Retail Density",
      key: "internalDetails",
      prop: "Commercial_Retail_Density",
      isString: false
    },
    {
      name: "Commercial Services Count",
      key: "internalDetails",
      prop: "Commercial_Services_Count",
      isString: false
    },
    {
      name: "Commercial Services Density",
      key: "internalDetails",
      prop: "Commercial_Services_Density",
      isString: false
    },
    {
      name: "Digitization Growth",
      key: "internalDetails",
      prop: "Digitization_Growth",
      isString: false
    },
    {
      name: "Finance Institution Density",
      key: "internalDetails",
      prop: "Finance_Institution_Density",
      isString: false
    },
    {
      name: "Financial Institution Count",
      key: "internalDetails",
      prop: "Financial_Institution_Count",
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isString: false
    },
    {
      name: "Healthcare Count",
      key: "internalDetails",
      prop: "Healthcare_Count",
      isString: false
    },
    {
      name: "Healthcare Density",
      key: "internalDetails",
      prop: "Healthcare_Density",
      isString: false
    },
    {
      name: "High Deliquency %",
      key: "internalDetails",
      prop: "High_Deliquency_%",
      isString: false
    },
    {
      name: "Infrastructure Count",
      key: "internalDetails",
      prop: "Infrastructure_Count",
      isString: false
    },
    {
      name: "Infrastructure Density",
      key: "internalDetails",
      prop: "Infrastructure_Density",
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isString: false
    },
    {
      name: "Lending Activity Value",
      key: "internalDetails",
      prop: "Lending_Activity_Value",
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isString: false
    },
    {
      name: "Market Leader",
      key: "internalDetails",
      prop: "Market_Leader",
      isString: true
    },
    {
      name: "Market Leader Penetration",
      key: "internalDetails",
      prop: "Market_Leader_Penetration",
      isString: false
    },
    {
      name: "Mean Resident Income",
      key: "internalDetails",
      prop: "Mean_Resident_Income",
      isString: false
    },
    {
      name: "Non Deliquency %",
      key: "internalDetails",
      prop: "Non_Deliquency_%",
      isString: false
    },
    {
      name: "Number Of Households",
      key: "internalDetails",
      prop: "Number_Of_Households",
      isString: false
    },
    {
      name: "Pincode Category",
      key: "internalDetails",
      prop: "Pincode_Category",
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isString: false
    },
    {
      name: "Transport Count",
      key: "internalDetails",
      prop: "Transport_Count",
      isString: false
    },
    {
      name: "Transport Density",
      key: "internalDetails",
      prop: "Transport_Density",
      isString: false
    },
    {
      name: "Utilities Count",
      key: "internalDetails",
      prop: "Utilities_Count",
      isString: false
    },
    {
      name: "Utilities Density",
      key: "internalDetails",
      prop: "Utilities_Density",
      isString: false
    }
  ]
};

const india1Exp: ExportColumnsObject = {
  exportType: "selected",
  columns: [
    { name: "Name", key: null, prop: "name", isString: true },
    { name: "Address", key: null, prop: "address", isString: true },
    {
      name: "ATM ID",
      key: "internalDetails",
      prop: "ATM_ID",
      isString: true
    },
    {
      name: "Live since",
      key: "internalDetails",
      prop: "month_live",
      isString: true
    },
    {
      name: "Cash Loading Type",
      key: "internalDetails",
      prop: "cash_loading_type",
      isString: true
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",
      isString: true
    },
    {
      name: "Hash",
      key: "internalDetails",
      prop: "hash",
      isString: true
    },
    {
      name: "Merchant Type",
      key: "internalDetails",
      prop: "merchant_type",
      isString: true
    },
    {
      name: "Pincode",
      key: "internalDetails",
      prop: "pincode",
      isString: true
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isString: true
    },
    {
      name: "Taluk",
      key: "internalDetails",
      prop: "taluk",
      isString: true
    },
    {
      name: "Town",
      key: "internalDetails",
      prop: "town",
      isString: true
    }
  ]
};

const layerSetsConfig: CreateLayerSetConfig[] = [
  {
    name: "Micro-Market Data",
    addString: "View Granularity Levels",
    layers: [
      {
        name: "Grids",
        deckLayerType: DeckLayerTypes.GRIDS,
        // gridSize: 650,
        layerType: LayerTypes.GRID,
        layerColor: layerColors[0],
        dataUrl: "/grid/search",
        prop: [],
        filterSetGroups: gridsFS,
        tableColumns: gridsTable,
        exportColumns: gridsExport,
        barredDeepDiveAttributes: []
      },
      // {
      //   name: "Box",
      //   deckLayerType: DeckLayerTypes.POLYGON,
      //   layerType: LayerTypes.SHAPE,
      //   layerColor: layerColors[2],
      //   dataUrl: "/shape/levelsearch-new",
      //   prop: ["Cluster"],
      //   filterSetGroups: boxFS,
      //   tableColumns: boxTable,
      //   exportColumns: boxExp,
      //   barredDeepDiveAttributes: []
      // },
      {
        name: "Pincode",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[1],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Pincode"],
        exportColumns: pincodeExp,
        tableColumns: pincodeTable,
        filterSetGroups: pincodeFSGroup,
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "My Layers",
    addString: "Add My Layer(s)",
    layers: [
      {
        name: "India1 ATM",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[2],
        dataUrl: "/poi/filter",
        prop: ["IndiaOne_ATM"],
        filterSetGroups: india1FS,
        tableColumns: india1TableColumns,
        exportColumns: india1Exp,
        barredDeepDiveAttributes: []
        // defaultTooltipColumns: [
        //   {
        //     name: "ATM ID",
        //     key: "internalDetails",
        //     prop: "ATM_ID",
        //     isString: true
        //   }
        // ]
      },
      {
        name: "ATM",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[3],
        dataUrl: "/poi/filter",
        prop: ["ATM"],
        filterSetGroups: atmFS,
        tableColumns: atmTableColumns,
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "Places",
    addString: "Add Places",
    layers: []
  }
];

export const india1SalesIntelConfig: PlatformConfig = {
  advancedDeepDive: false,
  apiUrl: "https://india1.stg.datasutram.com/api",
  barredPOIs: ["ATM", "IndiaOne_ATM"],
  enableKCLoginRedirect: false,
  designationUserRoleMapping: [],
  baseUrl: "https://india1.stg.datasutram.com",
  defaultFilterSetConfig: atmFS,
  defaultGeographyAreaThreshold: null,
  defaultTableConfig: atmTableColumns,
  elasticUrl: "https://india1.stg.datasutram.com/api/elastic-search",
  enableAdvancedSearch: true,
  enableDSMapsDashboard: false,
  enableGeographyMultiSelect: false,
  enableRaiseAnIssue: false,
  enableRankByFeature: false,
  enableRegistration: true,
  enableShowValuesForFeature: false,
  hasClusters: true,
  layerSetConfig: layerSetsConfig,
  poiRelationshipConfig: "poi-grid"
};
